import React from 'react';

import Layout from '../components/Layout';
import Contact from '../components/Contact';
import projectData from '../../content/projects.json';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header className='wrapper'>
        <h2>Projektliste</h2>
        <p>Überblick der letzten zehn Projekte</p>
      </header>
      <section className="wrapper style5">
        <Contact />
        {projectData.slice(0, 10).map((value) => {
          return <div className="inner">
            <header>
              <hr />
              {("timeframe" in value) ?
                (("start" in value.timeframe) ? (("end" in value.timeframe) ? '' : 'ab ') + value.timeframe.start : 'x') :
                ''}
              {("timeframe" in value) ?
                (("end" in value.timeframe) ? ' bis ' + value.timeframe.end : '') :
                ''}
              <h4>{value.name}</h4>
              <p><b>{value.client.name}</b>, {value.client.location}
                {("references" in value) ? 
                  (value.references.map((ref) => {
                    return <ref>,&nbsp;&nbsp;&nbsp;<a href={ref.url}>{ref.label}</a></ref>
                  })) : ''}</p>
            </header>
            {("tasks" in value) ? <div>
              <h5>Aufgaben</h5>
              <ul className="small">
                {value.tasks.map((task) => {
                  return <li>{task}</li>
                })}
              </ul>
            </div> : ''}
            {("roles" in value) ? <div>
              <h5>Rollen</h5>
              <div className='row'>
                {value.roles.map((role) => {
                  return <div className='col'>{role}</div>
                })}
              </div><p />
            </div> : ''}
            {("qualifications" in value) ? <div>
              <h5>Skills</h5>
              <div className='row'>
                {value.qualifications.map((skill) => {
                  return <div className='col'>{skill}</div>
                })}
              </div><p />
            </div> : ''}
          </div>;
        })}
      </section>
    </article>
  </Layout>
);

export default IndexPage;